import { Card } from "primereact/card";
import * as client from "../../../client";
import styles from "./Analytics.module.css";
import { useContext } from "react";
import { Chart } from "primereact/chart";
import { ThemeContext } from "../../../themes/Theme";

type DashboardAnalyticsProps = {
  specialtyReports: client.ReportMetadataResponse[];
};

export const DashboardAnalytics: React.FC<DashboardAnalyticsProps> = () => {
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";

  const gridLineColor = isLightMode
    ? "rgba(0, 0, 0, 0.1)"
    : "rgba(255, 255, 255, 0.2)";
  const textColor = isLightMode ? "#000000" : "#ffffff";

  return (
    <div className={styles.container}>
      <div className={styles.gridContainer}>
        <Card title="Submissions Overview" className={styles.analyticsCard}>
          <OverviewChart gridLineColor={gridLineColor} textColor={textColor} />
        </Card>
        <Card
          title="Monthly Submissions By Brokerage"
          className={styles.analyticsCard}
        >
          <BrokerageChart gridLineColor={gridLineColor} textColor={textColor} />
        </Card>
        <Card title="Submissions By NAICS" className={styles.analyticsCard}>
          <NaicsChart gridLineColor={gridLineColor} textColor={textColor} />
        </Card>
      </div>
    </div>
  );
};

type ChartProps = {
  gridLineColor: string;
  textColor: string;
};

const OverviewChart: React.FC<ChartProps> = ({ gridLineColor, textColor }) => {
  const chartData = {
    labels: ["Bound", "Declined", "Quoted", "In Flight"],
    datasets: [
      {
        label: "Submissions",
        data: [540, 325, 702, 620],
        backgroundColor: [
          "rgba(255, 159, 64, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
          "rgb(255, 159, 64)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
      x: {
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
  };

  return (
    <Chart
      type="bar"
      data={chartData}
      options={chartOptions}
      style={{ height: "270px" }}
    />
  );
};

const NaicsChart: React.FC<ChartProps> = ({ textColor }) => {
  const chartData = {
    labels: ["NAICS 531311", "NAICS 622110", "NAICS 339920", "NAICS 531110"],
    datasets: [
      {
        data: [15, 5, 2, 1],
        backgroundColor: [
          "rgba(255, 159, 64, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
          "rgb(255, 159, 64)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const chartOptions = {
    cutout: "60%",
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        right: 20,
        left: 20,
      },
    },
    y: {
      ticks: {
        color: textColor,
      },
    },
    x: {
      ticks: {
        color: textColor,
      },
    },
    plugins: {
      legend: {
        position: "left",
        labels: {
          color: textColor,
        },
      },
    },
  };

  return (
    <Chart
      type="doughnut"
      data={chartData}
      options={chartOptions}
      style={{ maxHeight: "260px" }}
    />
  );
};

const BrokerageChart: React.FC<ChartProps> = ({ gridLineColor, textColor }) => {
  const chartData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        type: "bar",
        label: "marsh",
        backgroundColor: "rgba(255, 159, 64, 0.2)",
        borderColor: "rgb(255, 159, 64)",
        borderWidth: 1,
        data: [50, 25, 12, 48, 90, 76, 42],
      },
      {
        type: "bar",
        label: "amwins",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgb(75, 192, 192)",
        borderWidth: 1,
        data: [21, 84, 24, 75, 37, 65, 34],
      },
      {
        type: "bar",
        label: "rtspecialty",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgb(54, 162, 235)",
        borderWidth: 1,
        data: [41, 52, 24, 74, 23, 21, 32],
      },
    ],
  };
  const chartOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      tooltips: {
        mode: "index",
        intersect: false,
      },
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
      x: {
        stacked: true,
        grid: {
          color: gridLineColor,
        },
        ticks: {
          color: textColor,
        },
      },
    },
  };

  return (
    <Chart
      type="bar"
      data={chartData}
      options={chartOptions}
      style={{ height: "270px" }}
    />
  );
};
