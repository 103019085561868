import { InputText } from "primereact/inputtext";
import { ListingObject, ListingType, listingTypes } from "../../api/types";
import styles from "./Shared.module.css";
import { ReactNode, useState } from "react";
import { Dropdown } from "primereact/dropdown";

export const displayNameForListingType = (listingType: ListingType) => {
  switch (listingType) {
    case "airbnb":
      return "Airbnb";
    case "vrbo":
      return "VRBO";
    case "evolve":
      return "Evolve";
    case "furnished_finder":
      return "Furnished Finder";
  }
};

const domainForListingType = (listingType: ListingType) => {
  switch (listingType) {
    case "airbnb":
      return "www.airbnb.com";
    case "vrbo":
      return "www.vrbo.com";
    case "evolve":
      return "www.evolve.com";
    case "furnished_finder":
      return "www.furnishedfinder.com";
  }
};

const placeholderForListingType = (listingType: ListingType) => {
  switch (listingType) {
    case "airbnb":
      return "123456789";
    case "vrbo":
      return "123456";
    case "evolve":
      return "123456";
    case "furnished_finder":
      return "123456_1";
  }
};

// Flip to true if we want to allow multiple listings in the same call
const MULTIPLE_LISTINGS_ENABLED = false;

type ListingIdFormProps = {
  textValues: ListingObject;
  setTextValues: React.Dispatch<React.SetStateAction<ListingObject>>;
};

export const ListingIdForm: React.FC<ListingIdFormProps> = ({
  textValues,
  setTextValues,
}) => {
  const [selectedListingType, setSelectedListingType] =
    useState<ListingType>("airbnb");

  return (
    <>
      {MULTIPLE_LISTINGS_ENABLED ? (
        <>
          <p>Enter up to 4 listing ids to generate a pdf.</p>
          {Object.keys(textValues).map((listingType) => {
            const listingKey = listingType as ListingType;
            return (
              <ListingInput
                key={listingType}
                listingType={listingKey}
                textValue={textValues[listingKey]}
                setTextValue={(value) => {
                  setTextValues((values) => {
                    const newValues = { ...values };
                    newValues[listingKey] = value;
                    return newValues;
                  });
                }}
              />
            );
          })}
        </>
      ) : (
        <>
          <p>Choose your listing provider and enter a listing id.</p>
          <div className={styles.singleListingContainer}>
            <ListingInput
              listingType={selectedListingType}
              textValue={textValues[selectedListingType]}
              setTextValue={(value) => {
                setTextValues((values) => {
                  const newValues = { ...values };
                  newValues[selectedListingType] = value;
                  return newValues;
                });
              }}
              dropdown={
                <Dropdown
                  value={{
                    name: displayNameForListingType(selectedListingType),
                    code: selectedListingType,
                  }}
                  onChange={(e) => {
                    const oldTextValue = textValues[selectedListingType];
                    const newListingType = e.value.code as ListingType;
                    setSelectedListingType(newListingType);
                    // Reset these on change so that we don't pass multiples
                    const resetListingValues = {
                      airbnb: "",
                      vrbo: "",
                      evolve: "",
                      furnished_finder: "",
                    };
                    resetListingValues[newListingType] = oldTextValue;
                    setTextValues(resetListingValues);
                  }}
                  options={listingTypes.map((listingType) => {
                    return {
                      name: displayNameForListingType(listingType),
                      code: listingType,
                    };
                  })}
                  optionLabel="name"
                />
              }
            />
          </div>
        </>
      )}
    </>
  );
};

type ListingInputProps = {
  listingType: ListingType;
  textValue: string;
  setTextValue: (value: string) => void;
  dropdown?: ReactNode;
};

const ListingInput: React.FC<ListingInputProps> = ({
  listingType,
  textValue,
  setTextValue,
  dropdown,
}) => {
  return (
    <div
      className={
        MULTIPLE_LISTINGS_ENABLED
          ? styles.listingTypeContainer
          : styles.listingTypeContainerSingle
      }
    >
      <InputText
        value={textValue}
        placeholder={placeholderForListingType(listingType)}
        onChange={(e) => setTextValue(e.target.value)}
        className={styles.inputText}
      />
      {dropdown && dropdown}
      <div className={styles.listingType}>
        <img
          src={`https://www.google.com/s2/favicons?domain=${domainForListingType(
            listingType
          )}&sz=${64}`}
          style={{ width: "20px", height: "20px" }}
        />
        <span>{displayNameForListingType(listingType)}</span>
      </div>
    </div>
  );
};
