import { InputText } from "primereact/inputtext";
import { useRef, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { login } from "./api/routes";
import styles from "./Splash.module.css";
import { Toast } from "primereact/toast";

type SplashProps = {
  onSuccessfulAuth: (clientId: string) => void;
};

export const Splash: React.FC<SplashProps> = ({ onSuccessfulAuth }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const toast = useRef<Toast>(null);

  const makeAuthCall = () => {
    setLoading(true);
    login(email, password).then((result) => {
      setLoading(false);

      if (result.success) {
        onSuccessfulAuth(result.clientId);
      } else {
        toast.current?.show({
          severity: "warn",
          summary: "Login Failed",
          detail: "Please enter a valid password.",
          life: 5000,
        });
      }
    });
  };

  return (
    <div className={styles.splashContainer}>
      <Toast ref={toast} />
      <Card title="Welcome!" className={styles.splashCard}>
        <span>Email</span>
        <InputText
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles.inputText}
          onKeyDown={(event) => {
            if (event.key === "Enter" && email && password) {
              makeAuthCall();
            }
          }}
        />
        <span>Password</span>
        <InputText
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={styles.inputText}
          onKeyDown={(event) => {
            if (event.key === "Enter" && email && password) {
              makeAuthCall();
            }
          }}
        />
        <Button
          className={styles.submitButton}
          label="Submit"
          disabled={password === ""}
          onClick={makeAuthCall}
          loading={loading}
        />
      </Card>
    </div>
  );
};
