import { ReportStatus } from "../../../api/types";
import styles from "./StatusIcon.module.css";
import { readableStatus } from "./util";

type StatusIconProps = {
  status: ReportStatus;
};

export const colorForStatus = (status: ReportStatus) => {
  switch (status) {
    case "bound":
      return "var(--green-600)";
    case "declined":
      return "var(--red-600)";
    case "in_flight":
      return "var(--primary-600)";
    case "uninterested":
      return "var(--yellow-600)";
  }
};

export const iconForStatus = (status: ReportStatus) => {
  switch (status) {
    case "bound":
      return "pi pi-lock";
    case "declined":
      return "pi pi-times";
    case "in_flight":
      return "pi pi-spinner-dotted";
    case "uninterested":
      return "pi pi-hourglass";
  }
};

export const StatusIcon: React.FC<StatusIconProps> = ({ status }) => {
  return (
    <div className={styles.container}>
      <i
        className={iconForStatus(status)}
        style={{
          color: colorForStatus(status),
        }}
      ></i>
      <span>{readableStatus(status)}</span>
    </div>
  );
};
