import styles from "./Renewal.module.css";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { FileUpload, FileUploadHandlerEvent } from "primereact/fileupload";
import { useMemo, useState } from "react";
import { downloadPdfZip, uploadFilesAdonis } from "../../api/routes";
import { useBatches } from "./data";
import * as client from "../../client";
import { ProgressSpinner } from "primereact/progressspinner";
import { PantheonOnly } from "../../dev";

const deleteBatch = async (epoch: string) => {
  return await client.deleteBatchAdonisDeleteBatchPost({ epoch });
};

const rerunBatch = async (epoch: string) => {
  return await client.restartBatchAdonisRestartBatchPost({ epoch });
};

const downloadZipFiles = async (folderName: string) => {
  // Download the zip file as a blob
  const blob = await downloadPdfZip(folderName);

  // Create a URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element and trigger a download
  const link = document.createElement("a");
  link.href = url;
  link.download = `${folderName}.zip`; // Set the desired filename
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const Renewal = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgressPercentOutOf100, setUploadProgressPercentOutOf100] =
    useState(0);

  const batches = useBatches();
  const renewalBatches: client.RenewalBatch[] = useMemo(
    () => (batches.isSuccess ? batches.data : []),
    [batches.isSuccess, batches.data]
  );

  const handleUpload = async (event: FileUploadHandlerEvent) => {
    if (isUploading) {
      return;
    }

    setIsUploading(true);
    setUploadProgressPercentOutOf100(0);
    await uploadFilesAdonis(event.files, (e) => {
      if (e.progress) {
        setUploadProgressPercentOutOf100(e.progress * 100);
      }
    });
    setIsUploading(false);
    batches.refetch();

    event.options.clear();
  };
  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        {batches.isLoading ? (
          <ProgressSpinner />
        ) : (
          <>
            {renewalBatches.map((batch) => (
              <RenewalBatch
                key={batch.created_at_ts}
                renewalBatch={batch}
                relist={batches.refetch}
              />
            ))}
          </>
        )}
      </div>
      <div className={styles.cardContainer}>
        <FileUpload
          name="files"
          accept="application/pdf"
          customUpload
          multiple
          chooseLabel="Select PDFs"
          auto={false} // Prevent automatic upload
          uploadHandler={handleUpload}
          className={styles.fileUpload}
          emptyTemplate={
            <span style={{ color: "var(--text-color-secondary)" }}>
              Drag and drop files here to upload
            </span>
          }
          progressBarTemplate={
            isUploading ? (
              <ProgressBar
                value={Math.round(uploadProgressPercentOutOf100)}
                style={{ height: "6px" }}
                displayValueTemplate={() => <></>}
              />
            ) : (
              <></>
            )
          }
        />
      </div>
    </div>
  );
};

type RenewalBatchProps = {
  renewalBatch: client.RenewalBatch;
  relist: () => void;
};

const formatDateString = (dateString: string): string => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

const RenewalBatch: React.FC<RenewalBatchProps> = ({
  renewalBatch,
  relist,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);
  if (renewalBatch.num_files === 0) {
    return <></>;
  }

  const hasProcessedAllFiles =
    renewalBatch.num_files === renewalBatch.num_processed;

  return (
    <div className={styles.statusContainer}>
      <div className={styles.statusContainerInner}>
        <div className={styles.statusRow}>
          <span>{formatDateString(renewalBatch.created_at_ts)}</span>
          {hasProcessedAllFiles ? (
            <span
              style={{ color: "var(--primary-color)" }}
            >{`${renewalBatch.num_files} files`}</span>
          ) : (
            <span
              style={{ color: "var(--primary-color)" }}
            >{`(${renewalBatch.num_processed}/${renewalBatch.num_files})`}</span>
          )}
        </div>
        {!hasProcessedAllFiles && (
          <ProgressBar
            style={{ height: "6px", borderRadius: "2px" }}
            value={(renewalBatch.num_processed / renewalBatch.num_files) * 100}
            displayValueTemplate={() => <></>}
          />
        )}
        {isDownloading && (
          <ProgressBar
            style={{ height: "6px", borderRadius: "2px" }}
            displayValueTemplate={() => <></>}
            mode="indeterminate"
          />
        )}
      </div>
      <Button
        size="small"
        className={styles.downloadButton}
        tooltip="Download report"
        disabled={!hasProcessedAllFiles || isDownloading}
        onClick={async () => {
          setIsDownloading(true);
          try {
            renewalBatch.processed_files_folder_name &&
              (await downloadZipFiles(
                renewalBatch.processed_files_folder_name
              ));
          } finally {
            setIsDownloading(false);
          }
        }}
        icon="pi pi-download"
      />
      <PantheonOnly>
        <Button
          size="small"
          className={styles.downloadButton}
          tooltip="Delete batch"
          onClick={async () => {
            await deleteBatch(
              (new Date(renewalBatch.created_at_ts).getTime() / 1000).toString()
            );
            await relist();
          }}
          icon="pi pi-trash"
        />
        <Button
          size="small"
          className={styles.downloadButton}
          tooltip="Delete and reprocess batch"
          onClick={async () => {
            await rerunBatch(
              (new Date(renewalBatch.created_at_ts).getTime() / 1000).toString()
            );
            await relist();
          }}
          icon="pi pi-undo"
        />
      </PantheonOnly>
    </div>
  );
};
