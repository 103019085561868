import { useMemo, useState } from "react";
import * as client from "../../../client";
import { InputNumber } from "primereact/inputnumber";
import styles from "./DeductibleCalculator.module.css";
import { formatCurrency } from "../util";
import { Divider } from "primereact/divider";
import { CATCloudIcon } from "./CloudIcon";

type RecalculatedLossInfo = {
  deductible: number;
  aggregateDeductible: number | undefined | null;
  totalNetIncurred: number;
  totalGrossPaid: number;
  lossYears: RecalculatedLossYear[];
};

type RecalculatedLossYear = {
  policyYear: string;
  netIncurred: number;
  grossPaid: number;
  losses: RecalculatedLoss[];
};

type RecalculatedLoss = {
  // The number paid adjusted for on leveling
  grossPaid: number;
  // The actual number paid
  grossPaidBase: number;
  onLevelMultiplier: number | undefined | null;
  netIncurred: number;
  // Used to show aggregate erosion
  aggregateLeft: number | undefined;
  isCat: boolean;
};

const getNumYearsAgo = (yearString: string) => {
  const currentYear = new Date().getFullYear();

  const strippedYear = yearString.trim();

  // For the past 50 years, just check if the string given date ends with that year
  for (let i = 0; i < 50; i++) {
    const year = currentYear - i;
    if (strippedYear.endsWith(year.toString())) {
      return i;
    }
  }

  // If this fails, just check if the last 2 match
  for (let i = 0; i < 50; i++) {
    const year = currentYear - i;
    const lastTwoDigits = year.toString().substring(2);
    if (strippedYear.endsWith(lastTwoDigits)) {
      return i;
    }
  }

  return undefined;
};

const onLevelMultiplierForYear = (
  onLevelBaseMultiplier: number | undefined | null,
  policyYear: string
) => {
  if (onLevelBaseMultiplier === null || onLevelBaseMultiplier === undefined) {
    return onLevelBaseMultiplier;
  }

  const numYearsAgo = getNumYearsAgo(policyYear);
  if (numYearsAgo === undefined) {
    return undefined;
  }

  return Math.pow(onLevelBaseMultiplier, numYearsAgo);
};

const recalculateLossInfo = (
  lossRunsByClaim: client.Loss[],
  deductible: number,
  catDeductible: number | undefined | null,
  aggregateDeductible: number | undefined | null,
  onLevelBaseMultiplier: number | undefined | null
): RecalculatedLossInfo | undefined => {
  if (lossRunsByClaim.length === 0) {
    return undefined;
  }

  // Group by year
  const losses: { [key: string]: client.Loss[] } = {};
  for (const loss of lossRunsByClaim) {
    if (Object.prototype.hasOwnProperty.call(losses, loss.policy_year)) {
      losses[loss.policy_year].push(loss);
    } else {
      losses[loss.policy_year] = [loss];
    }
  }

  const lossYears: RecalculatedLossYear[] = [];
  const lossesByYearKeys = Object.keys(losses).sort().reverse();
  let totalGrossPaid = 0;
  let totalNetIncurred = 0;

  for (const key of lossesByYearKeys) {
    let aggregateForYear = aggregateDeductible;
    let netIncurredForYear = 0;
    let grossPaidForYear = 0;
    const calculatedLosses: RecalculatedLoss[] = [];
    const lossesForYear = losses[key];

    for (const loss of lossesForYear) {
      const isCat = loss.is_cat_event ?? false;
      const grossPaidBase = loss.gross_loss_paid.value;
      const multiplierForYear = onLevelMultiplierForYear(
        onLevelBaseMultiplier,
        key
      );
      const grossPaid =
        multiplierForYear !== null && multiplierForYear !== undefined
          ? grossPaidBase * multiplierForYear
          : grossPaidBase;
      const occurrenceDeductible =
        (isCat && (catDeductible ?? 0) > 0 ? catDeductible : deductible) ?? 0;
      const occurrenceDeductiblePaid = Math.min(
        occurrenceDeductible,
        grossPaid
      );
      const leftoverLoss = grossPaid - occurrenceDeductiblePaid;
      const aggregatePaid =
        aggregateForYear !== undefined && aggregateForYear !== null
          ? Math.min(aggregateForYear, leftoverLoss)
          : 0;
      const amountPaidByCompany = occurrenceDeductiblePaid + aggregatePaid;

      const netIncurred = Math.max(0, grossPaid - amountPaidByCompany);
      const aggregateLeft =
        aggregateForYear !== undefined && aggregateForYear !== null
          ? aggregateForYear - aggregatePaid
          : undefined;

      const calculatedLoss: RecalculatedLoss = {
        grossPaid: grossPaid,
        grossPaidBase: grossPaidBase,
        onLevelMultiplier: multiplierForYear,
        netIncurred: netIncurred,
        aggregateLeft: aggregateLeft,
        isCat: isCat,
      };
      calculatedLosses.push(calculatedLoss);

      aggregateForYear = aggregateLeft;
      netIncurredForYear += netIncurred;
      grossPaidForYear += grossPaid;
    }

    const calculatedLossYear: RecalculatedLossYear = {
      policyYear: key,
      grossPaid: grossPaidForYear,
      netIncurred: netIncurredForYear,
      losses: calculatedLosses,
    };
    totalGrossPaid += grossPaidForYear;
    totalNetIncurred += netIncurredForYear;
    lossYears.push(calculatedLossYear);
  }

  return {
    deductible: deductible,
    aggregateDeductible: aggregateDeductible,
    totalGrossPaid: totalGrossPaid,
    totalNetIncurred: totalNetIncurred,
    lossYears: lossYears,
  };
};

type DeductibleCalculatorProps = {
  lossRunInfo: client.LossRunInfo;
  deductibleInfo: client.DeductibleInfo;
};

export const DeductibleCalculator: React.FC<DeductibleCalculatorProps> = ({
  lossRunInfo,
  deductibleInfo,
}) => {
  const [deductible, setDeductible] = useState<number | null | undefined>(
    deductibleInfo.proposed_occurrence_deductible ?? 0
  );
  const [catDeductible, setCatDeductible] = useState<number | null | undefined>(
    undefined
  );
  const [aggregateDeductible, setAggregateDeductible] = useState<
    number | null | undefined
  >(
    deductibleInfo.proposed_aggregate_deductible ?? 0
      ? deductibleInfo.proposed_aggregate_deductible
      : undefined
  );
  const [onLevelBaseMultiplier, setOnLevelBaseMultiplier] = useState<
    number | null | undefined
  >(1.03);

  if (
    lossRunInfo.loss_runs_by_claim === null ||
    lossRunInfo.loss_runs_by_claim.length === 0
  ) {
    return <span>No individual claims found</span>;
  }

  const hasAggregate = (aggregateDeductible ?? 0) > 0;
  const recalculatedLossInfo = useMemo(
    () =>
      recalculateLossInfo(
        lossRunInfo.loss_runs_by_claim ?? [],
        deductible ?? 0,
        catDeductible,
        hasAggregate ? aggregateDeductible : undefined,
        onLevelBaseMultiplier
      ),
    [deductible, aggregateDeductible, onLevelBaseMultiplier, catDeductible]
  );

  return (
    <>
      <div className={styles.inputsContainer}>
        <div className={styles.inputContainer}>
          <span className={styles.inputLabel}>Occurrence Deductible</span>
          <InputNumber
            inputId="currency-us"
            value={deductible}
            onValueChange={(e) => setDeductible(e.value)}
            mode="currency"
            currency="USD"
            locale="en-US"
          />
        </div>
        <div className={styles.inputContainer}>
          <span className={styles.inputLabel}>CAT Occurrence Deductible</span>
          <InputNumber
            inputId="currency-us"
            value={catDeductible}
            onValueChange={(e) => setCatDeductible(e.value)}
            mode="currency"
            currency="USD"
            locale="en-US"
          />
        </div>
        <div className={styles.inputContainer}>
          <span className={styles.inputLabel}>Aggregate Deductible</span>
          <InputNumber
            inputId="currency-us"
            value={aggregateDeductible}
            onValueChange={(e) => setAggregateDeductible(e.value)}
            mode="currency"
            currency="USD"
            locale="en-US"
            placeholder="None"
          />
        </div>
        <div className={styles.inputContainer}>
          <span className={styles.inputLabel}>On Level Multiplier</span>
          <InputNumber
            value={onLevelBaseMultiplier}
            onValueChange={(e) => setOnLevelBaseMultiplier(e.value)}
            min={1.0}
            max={2.0}
            maxFractionDigits={3}
            placeholder="None"
          />
        </div>
      </div>
      <div
        className={styles.mathRow}
        style={{
          fontWeight: "bold",
          marginTop: "20px",
          paddingTop: "16px",
          paddingBottom: "16px",
          marginBottom: "-20px",
          backgroundColor: "var(--surface-50)",
        }}
      >
        <div className={styles.mathColumn}>Year</div>
        <div className={styles.mathColumn}>Ground Up Loss</div>
        <div className={styles.mathColumn}>Deductible</div>
        {hasAggregate && (
          <div className={styles.mathColumn}>Aggregate Erosion</div>
        )}
        <div className={styles.mathColumn}>Net Incurred</div>
      </div>
      {recalculatedLossInfo && (
        <>
          <Divider />
          <div
            className={styles.mathRow}
            style={{
              fontWeight: "bold",
              marginTop: "8px",
              color: "var(--primary-color)",
            }}
          >
            <div
              className={styles.mathColumn}
            >{`All (${recalculatedLossInfo.lossYears.length} Years)`}</div>
            <div className={styles.mathColumn}>
              {formatCurrency(recalculatedLossInfo.totalGrossPaid)}
            </div>
            <div className={styles.mathColumn}>-</div>
            {hasAggregate && <div className={styles.mathColumn}>-</div>}
            <div className={styles.mathColumn}>
              {formatCurrency(recalculatedLossInfo.totalNetIncurred)}
            </div>
          </div>
          <Divider />
          {recalculatedLossInfo.lossYears.map((lossYear) => (
            <LossYear
              lossYear={lossYear}
              deductible={recalculatedLossInfo.deductible}
              catDeductible={catDeductible}
              key={lossYear.policyYear}
              hasAggregate={hasAggregate}
            />
          ))}
        </>
      )}
    </>
  );
};

type LossYearProps = {
  lossYear: RecalculatedLossYear;
  deductible: number;
  catDeductible: number | undefined | null;
  hasAggregate: boolean;
};

const LossYear: React.FC<LossYearProps> = ({
  lossYear,
  deductible,
  catDeductible,
  hasAggregate,
}) => {
  return (
    <>
      {lossYear.losses.map((loss, index) => (
        <MathRow
          key={index}
          policyYear={lossYear.policyYear}
          deductible={
            loss.isCat && (catDeductible ?? 0) > 0
              ? catDeductible ?? 0
              : deductible
          }
          grossPaid={loss.grossPaidBase}
          netIncurred={loss.netIncurred}
          aggregateLeft={loss.aggregateLeft}
          onLevelMultiplier={loss.onLevelMultiplier}
          isCat={loss.isCat}
        />
      ))}
      <div
        className={styles.mathRow}
        style={{ fontWeight: "bold", marginTop: "8px" }}
      >
        <div
          className={styles.mathColumn}
        >{`${lossYear.policyYear} - Total`}</div>
        <div className={styles.mathColumn}>
          {formatCurrency(lossYear.grossPaid)}
        </div>
        <div className={styles.mathColumn}>-</div>
        {hasAggregate && <div className={styles.mathColumn}>-</div>}
        <div className={styles.mathColumn}>
          {formatCurrency(lossYear.netIncurred)}
        </div>
      </div>
      <Divider />
    </>
  );
};

type MathRowProps = {
  policyYear: string;
  grossPaid: number;
  deductible: number;
  netIncurred: number;
  aggregateLeft: number | undefined;
  onLevelMultiplier: number | undefined | null;
  isCat: boolean;
};

const MathRow: React.FC<MathRowProps> = ({
  policyYear,
  grossPaid,
  deductible,
  netIncurred,
  aggregateLeft,
  onLevelMultiplier,
  isCat,
}) => {
  const showMultiplier = (onLevelMultiplier ?? 1) > 1;
  return (
    <div className={styles.mathRow}>
      <div className={styles.mathColumn}>{policyYear}</div>
      <div className={styles.mathColumn}>
        {showMultiplier
          ? `${formatCurrency(grossPaid)} x ${onLevelMultiplier?.toFixed(2)}`
          : formatCurrency(grossPaid)}
      </div>
      <div className={styles.mathColumn}>
        {isCat ? (
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <span>{formatCurrency(deductible)}</span>
            <CATCloudIcon withTooltip />
          </div>
        ) : (
          formatCurrency(deductible)
        )}
      </div>
      {aggregateLeft !== undefined && (
        <div className={styles.mathColumn}>{formatCurrency(aggregateLeft)}</div>
      )}
      <div className={styles.mathColumn}>{formatCurrency(netIncurred)}</div>
    </div>
  );
};
