import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import * as client from "../../../client";
import { ExactMoney } from "../Money";
import { Tooltip } from "primereact/tooltip";
import { useRandomId } from "../../../util";
import styles from "./LossRunCard.module.css";
import { TabView, TabPanel } from "primereact/tabview";
import { DeductibleCalculator } from "./DeductibleCalculator";
import { CATCloudIcon } from "./CloudIcon";

// XXX very dangerous
const TerribleMoney = ({
  dollars,
  fieldName,
  errors,
}: {
  dollars?: number;
  fieldName?: string;
  errors?: client.CouldNotParse[];
}) => {
  const id = useRandomId();
  if (dollars === undefined) {
    return <></>;
  }
  const cents = Math.round(dollars * 100);
  const showWarning = errors && errors.length > 0;
  return (
    <>
      {showWarning ? (
        <Tooltip
          target={"." + id}
          updateDelay={1000}
          className={styles.tooltip}
        >
          Could not extract a {fieldName} value from the following cells:
          <ul>
            {errors.map((e) => (
              <li>"{e.value}"</li>
            ))}
          </ul>
        </Tooltip>
      ) : null}
      <span className={id}>
        <ExactMoney cents={cents} />
        {showWarning ? (
          <span
            className={`pi pi-exclamation-triangle ${styles.warningIcon}`}
          />
        ) : null}
      </span>
    </>
  );
};

type LossRunCardProps = {
  lossRunInfo: client.LossRunInfo;
  deductibleInfo: client.DeductibleInfo;
};

export const LossRunCard: React.FC<LossRunCardProps> = ({
  lossRunInfo,
  deductibleInfo,
}) => {
  return (
    <Card title="Loss Run">
      <TabView style={{ marginTop: "-16px" }}>
        <TabPanel header="By Year">
          <YearByYearLossRunTable lossRunInfo={lossRunInfo} />
        </TabPanel>
        <TabPanel header="By Claim">
          <ClaimByClaimLossRunTable lossRunInfo={lossRunInfo} />
        </TabPanel>
        <TabPanel header="Deductible Calculator">
          <DeductibleCalculator
            lossRunInfo={lossRunInfo}
            deductibleInfo={deductibleInfo}
          />
        </TabPanel>
      </TabView>
    </Card>
  );
};

type LossRunTableProps = {
  lossRunInfo: client.LossRunInfo;
};

const YearByYearLossRunTable: React.FC<LossRunTableProps> = ({
  lossRunInfo,
}) => {
  const lossRuns =
    lossRunInfo.computed_loss_runs_by_year.length > 0
      ? lossRunInfo.computed_loss_runs_by_year
      : lossRunInfo.parsed_loss_runs_by_year;

  const showNetIncurred =
    lossRuns.length === 0 ||
    lossRuns.some((run) => run.net_incurred?.value !== undefined);
  return (
    <DataTable value={lossRuns}>
      <Column sortable field="policy_year" header="Year" />
      <Column
        field="description_of_losses"
        header="Description"
        style={{ maxWidth: "300px" }}
      />
      <Column header="Open Claims" field="open_claims" />
      <Column header="Closed Claims" field="closed_claims" />
      <Column
        header="Total Claims"
        body={(data: client.LossRunByYear) =>
          data.open_claims + data.closed_claims
        }
      />
      <Column
        field="gross_loss_paid"
        header="Ground Up Loss"
        body={(data: client.LossRunByYear) => (
          <TerribleMoney
            dollars={data.gross_loss_paid.value}
            fieldName="Ground Up Loss"
            errors={data.gross_loss_paid.errors}
          />
        )}
      />
      {showNetIncurred ? (
        <Column
          field="net_incurred"
          header="Net Incurred"
          body={(data: client.LossRunByYear) =>
            data.net_incurred ? (
              <TerribleMoney
                dollars={data.net_incurred.value}
                fieldName="Net Incurred"
                errors={data.net_incurred.errors}
              />
            ) : null
          }
        />
      ) : null}
    </DataTable>
  );
};

const ClaimByClaimLossRunTable: React.FC<LossRunTableProps> = ({
  lossRunInfo,
}) => {
  if (
    lossRunInfo.loss_runs_by_claim === null ||
    lossRunInfo.loss_runs_by_claim.length === 0
  ) {
    return <span>No individual claims found</span>;
  }

  return (
    <DataTable value={lossRunInfo.loss_runs_by_claim}>
      <Column sortable field="policy_year" header="Year" />
      <Column
        field="description"
        header="Description"
        style={{ maxWidth: "300px" }}
      />
      <Column
        field="gross_loss_paid"
        header="Ground Up Loss"
        body={(data: client.Loss) => (
          <TerribleMoney
            dollars={data.gross_loss_paid.value}
            fieldName="Ground Up Loss"
            errors={data.gross_loss_paid.errors}
          />
        )}
      />
      <Column
        field="net_incurred"
        header="Net Incurred"
        body={(data: client.Loss) =>
          data.net_incurred ? (
            <TerribleMoney
              dollars={data.net_incurred.value}
              fieldName="Net Incurred"
              errors={data.net_incurred.errors}
            />
          ) : null
        }
      />
      <Column
        field="is_cat_event"
        header="Is CAT Event"
        body={(data: client.Loss) =>
          data.is_cat_event ? (
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <span>Yes</span>
              <CATCloudIcon />
            </div>
          ) : (
            <span>No</span>
          )
        }
      />
    </DataTable>
  );
};
