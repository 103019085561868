import styles from "./CompanyMemberCard.module.css";

type CompanyMemberCardProps = {
  name: string;
  title: string;
  infoLines: string[];
  imageSrc: string;
};

export const CompanyMemberCard: React.FC<CompanyMemberCardProps> = ({
  name,
  title,
  infoLines,
  imageSrc,
}) => {
  return (
    <div className={styles.cardContainer}>
      <img src={imageSrc} className={styles.imgContainer} />
      <span className={styles.nameText}>{name}</span>
      <span className={styles.titleText}>{title}</span>
      <div className={styles.infoTextContainer}>
        {infoLines.map((infoText, index) => (
          <div className={styles.infoTextRow} key={index}>
            <span className={styles.infoText}>•</span>
            <span className={styles.infoText}>{infoText}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
