import { InputText } from "primereact/inputtext";
import styles from "./Dashboard.module.css";
import { useMemo, useState } from "react";
import { ReportStatus } from "../../../api/types";
import { SpecialtyReport } from "../SpecialtyReport";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import {
  DashboardHeaderRow,
  DashboardLoadingRows,
  DashboardRow,
} from "./DashboardRow";
import { Dropdown } from "primereact/dropdown";
import {
  appetiteScoreForReportInfo,
  brokerageFromBroker,
  readableStatus,
} from "./util";
import { useReports } from "../data";
import * as client from "../../../client";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { AppetiteModal } from "../appetite/AppetiteModal";
import { DashboardAnalytics } from "./Analytics";
import { User, USERS } from "./users";
import { PantheonAvatar } from "../../../components/avatar/Avatar";
import { UseQueryResult } from "@tanstack/react-query";

type SortType =
  | "recency_newest"
  | "recency_oldest"
  | "appetite_highest"
  | "appetite_lowest";

type StatusOption = {
  name: string;
  code: ReportStatus;
};

export const Dashboard = () => {
  const apiReports = useReports();
  const specialtyReports: client.ReportMetadataResponse[] = useMemo(
    () => (apiReports.isSuccess ? apiReports.data : []),
    [apiReports.isSuccess, apiReports.data]
  );

  return (
    <Routes>
      <Route path="report/:id" element={<SpecialtyReport />} />
      <Route
        path="analytics"
        element={<DashboardAnalytics specialtyReports={specialtyReports} />}
      />
      <Route
        path="reports/casualty"
        element={
          <Reports
            isCasualty={true}
            apiReports={apiReports}
            specialtyReports={specialtyReports}
          />
        }
      />
      <Route
        path="reports/property"
        element={
          <Reports
            isCasualty={false}
            apiReports={apiReports}
            specialtyReports={specialtyReports}
          />
        }
      />
      <Route path="*" element={<Navigate to={ROUTES.DASHBOARD_PROPERTY} />} />
    </Routes>
  );
};

type ReportsProps = {
  apiReports: UseQueryResult<client.ReportMetadataResponse[], Error>;
  specialtyReports: client.ReportMetadataResponse[];
  isCasualty: boolean;
};

const Reports: React.FC<ReportsProps> = ({
  apiReports,
  specialtyReports,
  isCasualty,
}) => {
  const [searchText, setSearchText] = useState("");
  const [sortType, setSortType] = useState<SortType>("appetite_highest");
  const [selectedAssignees, setSelectedAssignees] = useState<User[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<StatusOption[]>([]);
  const statuses = Object.values(ReportStatus).map((status) => {
    return {
      name: readableStatus(status),
      code: status,
    };
  });

  const [
    selectedReportIdForBusinessClassification,
    setSelectedReportIdForBusinessClassification,
  ] = useState<string | undefined>(undefined);
  const selectedReportForBusinessClassification = specialtyReports.find(
    (report) => report.report_id === selectedReportIdForBusinessClassification
  );

  const navigate = useNavigate();

  const filteredAndSortedReports = useMemo(() => {
    const filteredReports = specialtyReports.filter((report) => {
      if (selectedStatuses.length > 0) {
        if (!selectedStatuses.some((status) => status.code === report.status)) {
          return false;
        }
      }

      if (selectedAssignees.length > 0) {
        if (!selectedAssignees.some((user) => user.email === report.assignee)) {
          return false;
        }
      }

      if (searchText !== "") {
        const searchTextLower = searchText.toLowerCase();
        if (
          // TODO: what else should we search on?
          !report.report_metadata.company_info.company_name
            .toLowerCase()
            .includes(searchTextLower) &&
          !brokerageFromBroker(report.report_metadata.company_info.broker_name)
            .toLowerCase()
            .includes(searchTextLower)
        ) {
          return false;
        }
      }

      // TODO: add a flag to the backend for casualty status
      if (
        (report.report_metadata.company_info.company_name ==
          "Lake Forest II") !=
        isCasualty
      ) {
        return false;
      }

      return true;
    });

    filteredReports.sort((a, b) => {
      const appetiteScoreA = appetiteScoreForReportInfo(
        a.report_metadata.business_classification,
        a.report_metadata.appetite_score
      );
      const appetiteScoreB = appetiteScoreForReportInfo(
        b.report_metadata.business_classification,
        b.report_metadata.appetite_score
      );

      const createdAtA = new Date(a.created_at_ts).getTime();
      const createdAtB = new Date(b.created_at_ts).getTime();

      if (sortType === "appetite_highest") {
        if (appetiteScoreB === appetiteScoreA) {
          return createdAtB - createdAtA;
        }
        return appetiteScoreB - appetiteScoreA;
      } else if (sortType === "appetite_lowest") {
        if (appetiteScoreB === appetiteScoreA) {
          return createdAtB - createdAtA;
        }
        return appetiteScoreA - appetiteScoreB;
      } else if (sortType === "recency_newest") {
        return createdAtB - createdAtA;
      }
      return createdAtA - createdAtB;
    });

    return filteredReports;
  }, [
    sortType,
    selectedStatuses,
    specialtyReports,
    searchText,
    selectedAssignees,
    isCasualty,
  ]);

  return (
    <>
      <div className={styles.searchContainer}>
        <InputText
          className={styles.searchInput}
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div className={styles.subSearchContainer}>
          <div className={styles.filterSortContainer}>
            <div className={styles.chipContainer}>
              <StatusFilterDropdown
                statuses={statuses}
                selectedStatuses={selectedStatuses}
                setSelectedStatuses={setSelectedStatuses}
              />
            </div>
            <div className={styles.chipContainer}>
              <AssigneeFilterDropdown
                selectedUsers={selectedAssignees}
                setSelectedUsers={setSelectedAssignees}
              />
            </div>
            <div className={styles.chipContainer}>
              <SortByDropdown setRecencySortType={setSortType} />
            </div>
          </div>
          <Button
            label="Analytics"
            rounded
            className={styles.chipButton}
            icon="pi pi-chart-line"
            onClick={() => navigate(ROUTES.DASHBOARD_ANALYTICS)}
          />
        </div>
        <div className={styles.rowsContainer}>
          <DashboardHeaderRow />
          {apiReports.isPending ? <DashboardLoadingRows /> : null}
          {filteredAndSortedReports.map((report) => (
            <DashboardRow
              key={report.report_id}
              specialtyInfo={report}
              onClick={() =>
                navigate(ROUTES.DASHBOARD_REPORT(report.report_id))
              }
              setReportIdForBusinessClassification={(id) =>
                setSelectedReportIdForBusinessClassification(id)
              }
            />
          ))}
        </div>
      </div>
      {selectedReportForBusinessClassification && (
        <AppetiteModal
          report={selectedReportForBusinessClassification.report_metadata}
          visible={selectedReportForBusinessClassification !== undefined}
          onHide={() => setSelectedReportIdForBusinessClassification(undefined)}
        />
      )}
    </>
  );
};

type SortByDropdownProps = {
  setRecencySortType: (sortType: SortType) => void;
};

const SortByDropdown: React.FC<SortByDropdownProps> = ({
  setRecencySortType,
}) => {
  const [selectedSortByOption, setSelectedSortByOption] = useState(null);
  const sortOptions = [
    { name: "Recency - newest to oldest", code: "recency_newest" },
    { name: "Recency - oldest to newest", code: "recency_oldest" },
    { name: "Appetite - highest to lowest", code: "appetite_highest" },
    { name: "Appetite - lowest to highest", code: "appetite_lowest" },
  ];

  return (
    <Dropdown
      value={selectedSortByOption}
      onChange={(e) => {
        setSelectedSortByOption(e.value);
        setRecencySortType(e.value.code);
      }}
      options={sortOptions}
      optionLabel="name"
      placeholder="Sort by"
      className={styles.multiSelect}
    />
  );
};

type StatusFilterDropdownProps = {
  statuses: StatusOption[];
  selectedStatuses: StatusOption[];
  setSelectedStatuses: (statuses: StatusOption[]) => void;
};

const StatusFilterDropdown: React.FC<StatusFilterDropdownProps> = ({
  statuses,
  selectedStatuses,
  setSelectedStatuses,
}) => {
  return (
    <MultiSelect
      value={selectedStatuses}
      onChange={(e) => setSelectedStatuses(e.value)}
      options={statuses}
      optionLabel="name"
      placeholder={"Status"}
      maxSelectedLabels={3}
      className={styles.multiSelect}
    />
  );
};

type AssigneeFilterDropdownProps = {
  selectedUsers: User[];
  setSelectedUsers: (users: User[]) => void;
};

const avatarTemplate = (user: User) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <PantheonAvatar assigneeEmail={user.email} />
      <span>{user.name}</span>
    </div>
  );
};

const AssigneeFilterDropdown: React.FC<AssigneeFilterDropdownProps> = ({
  selectedUsers,
  setSelectedUsers,
}) => {
  return (
    <MultiSelect
      value={selectedUsers}
      onChange={(e) => setSelectedUsers(e.value)}
      options={Object.values(USERS)}
      itemTemplate={avatarTemplate}
      optionLabel="name"
      placeholder={"Assignee"}
      maxSelectedLabels={3}
      className={styles.multiSelect}
    />
  );
};
