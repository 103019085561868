type PdfType = "tos" | "privacy_policy";

type EmbeddedPdfProps = {
  pdfType: PdfType;
};

export const EmbeddedPdf: React.FC<EmbeddedPdfProps> = ({ pdfType }) => {
  const fileUrl =
    process.env.PUBLIC_URL + pdfType === "privacy_policy"
      ? "/privacy-policy.pdf"
      : "tos.pdf";

  return (
    <embed src={fileUrl} type="application/pdf" width="100%" height="100%" />
  );
};
