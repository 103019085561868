import { InputText } from "primereact/inputtext";
import styles from "./Shared.module.css";

type PropertyLinksFromProps = {
  urlTextValue1: string;
  setUrlTextValue1: (value: string) => void;
  urlTextValue2: string;
  setUrlTextValue2: (value: string) => void;
  urlTextValue3: string;
  setUrlTextValue3: (value: string) => void;
};

export const PropertyLinksForm: React.FC<PropertyLinksFromProps> = ({
  urlTextValue1,
  urlTextValue2,
  urlTextValue3,
  setUrlTextValue1,
  setUrlTextValue2,
  setUrlTextValue3,
}) => {
  return (
    <>
      <p>Enter up to 3 property links.</p>
      <InputText
        placeholder="https://www.zillow.com/..."
        className={styles.inputText}
        value={urlTextValue1}
        onChange={(e) => setUrlTextValue1(e.target.value)}
      />
      <InputText
        placeholder="https://www.redfin.com/..."
        className={styles.inputText}
        value={urlTextValue2}
        onChange={(e) => setUrlTextValue2(e.target.value)}
      />
      <InputText
        placeholder="https://www.trulia.com/..."
        className={styles.inputText}
        value={urlTextValue3}
        onChange={(e) => setUrlTextValue3(e.target.value)}
      />
    </>
  );
};
