import { apiTypes } from "./routes";

export type NearestAirbnb = {
  listingId: string;
  distanceText: string;
};

export const listingTypes = [
  "airbnb",
  "vrbo",
  "evolve",
  "furnished_finder",
] as const;

export type ListingType = (typeof listingTypes)[number];

export type ListingObject = {
  [key in ListingType]: string;
};

export type AuthenticationResponse = "success" | "failure" | "unauthorized";

export type AddressInputInfo = {
  address: string;
  totalInsuredValue: number;
};

export type PropertyInfo = {
  address: string;
  totalInsuredValue: number;
};

export type EnvironmentalRiskInfo = {
  censusTract: string;
  numAddresses: number;
  hurricaneRisk: string;
  tornadoRisk: string;
  earthquakeRisk: string;
  wildfireRisk: string;
  winterStormRisk: string;
};

export type FireRiskCluster = {
  addresses: string[];
  clusterId: string;
  numAddresses: number;
  totalInsuredValue: number;
};

export type NaicsInfo = {
  naicsCode: string;
  naicsDescription: string;
};
export type RiskSignal = {
  title: string;
  description: string;
};

export const ReportStatus = apiTypes.status;
export type ReportStatus = apiTypes.status;
