import { useQuery } from "@tanstack/react-query";
import { listBatchesAdonis } from "../../api/routes";

export const useBatches = () => {
  return useQuery({
    queryKey: ["batches"],
    queryFn: async () => {
      return await listBatchesAdonis();
    },
    refetchInterval: 5000,
  });
};
