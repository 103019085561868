import { Checkbox } from "primereact/checkbox";

import { useState } from "react";
import styles from "./DashboardRow.module.css";
import { ReportActionMenu } from "./ReportActionMenu";
import { StatusIcon } from "./StatusIcon";
import classNames from "classnames";
import * as client from "../../../client";
import { Skeleton } from "primereact/skeleton";
import { PantheonAvatar } from "../../../components/avatar/Avatar";
import { RiskSignalIcon } from "../RiskDetailsCard";
import { AppetiteIcon } from "../appetite/AppetiteIcon";
import { appetiteScoreForReportInfo, brokerageFromBroker } from "./util";

type DashboardRowProps = {
  specialtyInfo: client.ReportMetadataResponse;
  onClick: () => void;
  setReportIdForBusinessClassification: (id: string) => void;
};

export const DashboardRow: React.FC<DashboardRowProps> = ({
  specialtyInfo,
  onClick,
  setReportIdForBusinessClassification,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [actionMenuIsOpen, setActionMenuIsOpen] = useState(false);

  return (
    <div
      tabIndex={0}
      role="button"
      className={classNames(
        styles.specialtyRow,
        actionMenuIsOpen && styles.specialtyRowHover
      )}
      onClick={onClick}
      onKeyDown={(event) => {
        if (event.key === "Enter" || event.key === " ") {
          onClick();
        }
      }}
    >
      <div className={styles.rowContentSmall}>
        <Checkbox
          onChange={(e) => setIsChecked(e.checked ?? false)}
          checked={isChecked}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </div>
      <div className={styles.rowContentSmall}>
        <PantheonAvatar assigneeEmail={specialtyInfo.assignee} />
      </div>
      <div className={classNames(styles.rowContent, styles.twoLineText)}>
        {specialtyInfo.report_metadata.company_info.company_name}
      </div>
      <div className={styles.rowContent}>
        <AppetiteIcon
          justifyContent="flex-start"
          fontSize="10px"
          appetite={appetiteScoreForReportInfo(
            specialtyInfo.report_metadata.business_classification,
            specialtyInfo.report_metadata.appetite_score
          )}
          onClick={() =>
            setReportIdForBusinessClassification(specialtyInfo.report_id)
          }
        />
      </div>
      <div className={styles.rowContent}>
        {brokerageFromBroker(
          specialtyInfo.report_metadata.company_info.broker_name
        )}
      </div>
      <div className={styles.rowContent}>
        {specialtyInfo.report_metadata.company_info.effective_date}
      </div>
      <div className={styles.rowContent}>
        <StatusIcon status={specialtyInfo.status} />
      </div>
      <div className={styles.rowContent}>
        <div className={styles.signalContainer}>
          <span>
            {specialtyInfo.report_metadata.risk_details.positive.length}
          </span>
          <RiskSignalIcon signalType="positive" />
        </div>
        <div className={styles.signalContainer}>
          <span>
            {specialtyInfo.report_metadata.risk_details.negative.length}
          </span>
          <RiskSignalIcon signalType="negative" />
        </div>
        <div className={styles.signalContainer}>
          <span>
            {specialtyInfo.report_metadata.risk_details.disqualifying.length}
          </span>
          <RiskSignalIcon signalType="disqualifying" />
        </div>
      </div>
      <div className={styles.rowContentSmall}>
        <ReportActionMenu report={specialtyInfo} />
      </div>
    </div>
  );
};

export const DashboardHeaderRow = () => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div
      className={styles.specialtyRow}
      style={{
        backgroundColor: "var(--surface-100)",
        fontWeight: "600",
        color: "var(--text-color-secondary)",
        cursor: "auto",
      }}
    >
      <div className={styles.rowContentSmall}>
        <Checkbox
          onChange={(e) => setIsChecked(e.checked ?? false)}
          checked={isChecked}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </div>
      <div className={styles.rowContentSmall} />
      <div className={styles.rowContent}>Company Name</div>
      <div className={styles.rowContent}>Appetite</div>
      <div className={styles.rowContent}>Brokerage</div>
      <div className={styles.rowContent}>Effective Date</div>
      <div className={styles.rowContent}>Status</div>
      <div className={styles.rowContent}>Risk Signals</div>
    </div>
  );
};

export const DashboardLoadingRows = () => {
  return (
    <>
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
    </>
  );
};

const SkeletonRow = () => {
  return (
    <div className={styles.specialtyRow}>
      <div className={styles.rowContentSmall} />
      <div className={styles.rowContentSmall}>
        <Skeleton height="32px" />
      </div>
      <div className={styles.rowContent}>
        <Skeleton height="32px" />
      </div>
      <div className={styles.rowContent}>
        <Skeleton height="32px" />
      </div>
      <div className={styles.rowContent}>
        <Skeleton height="32px" />
      </div>
      <div className={styles.rowContent}>
        <Skeleton height="32px" />
      </div>
      <div className={styles.rowContent}>
        <Skeleton height="32px" />
      </div>
      <div className={styles.rowContent}>
        <Skeleton height="32px" />
      </div>
      <div className={styles.rowContentSmall}>
        <Skeleton height="32px" />
      </div>
    </div>
  );
};
