export type User = {
  name: string;
  photoUrl: string;
  email: string;
};

export const USERS: {
  [email: string]: { name: string; photoUrl: string; email: string };
} = {
  "greg@pantheonai.co": {
    name: "Greg Karabinos",
    photoUrl:
      "https://media.licdn.com/dms/image/v2/D4E03AQF20DcvJ7OLZQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1711984724453?e=1733961600&v=beta&t=YlB21uHArm5bt3Ha1yjHB5yClaZuXex7-VR956unnhM",
    email: "greg@pantheonai.co",
  },
  "michael@canopius.com": {
    name: "Michael Davis",
    photoUrl:
      "https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png",
    email: "michael@canopius.com",
  },
  "rachel@canopius.com": {
    name: "Rachel Barnes",
    photoUrl:
      "https://primefaces.org/cdn/primereact/images/avatar/asiyajavayant.png",
    email: "rachel@canopius.com",
  },
  "hank@canopius.com": {
    name: "Hank Jameson",
    photoUrl:
      "https://primefaces.org/cdn/primereact/images/avatar/onyamalimba.png",
    email: "hank@canopius.com",
  },
};
