import styles from "./Security.module.css";
import { Panel } from "primereact/panel";

export const Security = () => {
  return (
    <div className={styles.container}>
      <span className={styles.headerText}>SECURITY AND PRIVACY</span>
      <Panel header="Network Security" className={styles.panel}>
        <div className={styles.infoTextContainer}>
          {[
            "We implement strong network security measures, including data encryption both in transit and at rest.",
            "Our secure cloud environment is maintained using Google Cloud's infrastructure expertise.",
            "Continuous monitoring and regular audits of network activities are conducted to identify and prevent unauthorized access.",
          ].map((infoText, index) => (
            <div className={styles.infoTextRow} key={index}>
              <span>•</span>
              <span>{infoText}</span>
            </div>
          ))}
        </div>
      </Panel>
      <Panel header="Product Security" className={styles.panel}>
        <div className={styles.infoTextContainer}>
          {[
            "Security of our platform is assessed through quarterly vulnerability scans.",
            "Annual penetration tests are performed to validate the effectiveness of our security measures.",
          ].map((infoText, index) => (
            <div className={styles.infoTextRow} key={index}>
              <span>•</span>
              <span>{infoText}</span>
            </div>
          ))}
        </div>
      </Panel>
      <Panel header="Enterprise Security" className={styles.panel}>
        <div className={styles.infoTextContainer}>
          {[
            "We enforce a comprehensive information security policy that governs all aspects of data handling and protection.",
            "Regular security trainings ensure all team members understand and adhere to the information security policy.",
            "We have established incident management procedures to effectively respond to any security breaches.",
            "Multi-factor authentication is required for all core company systems.",
          ].map((infoText, index) => (
            <div className={styles.infoTextRow} key={index}>
              <span>•</span>
              <span>{infoText}</span>
            </div>
          ))}
        </div>
      </Panel>
      <Panel header="Availability" className={styles.panel}>
        <div className={styles.infoTextContainer}>
          {[
            "Our platform is updated regularly, eliminating the need for scheduled maintenance downtime.",
            "High service availability is ensured through built-in redundancy mechanisms.",
          ].map((infoText, index) => (
            <div className={styles.infoTextRow} key={index}>
              <span>•</span>
              <span>{infoText}</span>
            </div>
          ))}
        </div>
      </Panel>
    </div>
  );
};
