import { Splash } from "./Splash";
import { useContext, useEffect, useState } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Wordmark } from "./components/wordmark/Wordmark";
import styles from "./LoggedOut.module.css";
import { Company } from "./pages/company/Company";
import { Landing } from "./pages/landing/Landing";
import { motion } from "framer-motion";
import { ThemeContext } from "./themes/Theme";
import { Security } from "./pages/security/Security";
import { TermsOfService } from "./pages/security/TermsOfService";
import { PrivacyPolicy } from "./pages/security/PrivacyPolicy";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "./routes";

type LoggedOutProps = {
  onSuccessfulAuth: (clientId: string) => void;
};

export const LoggedOut: React.FC<LoggedOutProps> = ({ onSuccessfulAuth }) => {
  const [hasShownAdditionalPage, setHasShownAdditionalPage] = useState(false);
  const { setThemeOverride } = useContext(ThemeContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(ROUTES.LANDING);
  }, []);

  useEffect(() => {
    if (location.pathname !== ROUTES.LANDING) {
      setHasShownAdditionalPage(true);
    }

    setThemeOverride("dark");

    return () => {
      setThemeOverride(undefined);
    };
  }, [location.pathname, setThemeOverride]);

  return (
    <div className={styles.container}>
      <Toolbar
        className={styles.toolbar}
        start={<Wordmark onClick={() => navigate(ROUTES.LANDING)} />}
        end={
          <>
            <Button
              text
              onClick={() => navigate(ROUTES.SIGN_IN)}
              className={styles.linkButton}
            >
              SIGN IN
            </Button>
            {/*
            We can comment this back in when we want to show company members
            <Button
              text
              onClick={() => setPage("company")}
              className={styles.linkButton}
            >
              COMPANY
            </Button>
        */}
          </>
        }
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={styles.contentContainer}
        key={location.pathname}
        style={
          location.pathname === ROUTES.SECURITY
            ? { justifyContent: "flex-start" }
            : {}
        }
      >
        <Routes>
          <Route
            path={ROUTES.LANDING}
            element={<Landing withInitialAnimation={!hasShownAdditionalPage} />}
          />
          <Route
            path={ROUTES.SIGN_IN}
            element={<Splash onSuccessfulAuth={onSuccessfulAuth} />}
          />
          <Route path={ROUTES.COMPANY} element={<Company />} />
          <Route path={ROUTES.SECURITY} element={<Security />} />
          <Route path={ROUTES.TOS} element={<TermsOfService />} />
          <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        </Routes>
      </motion.div>
      <div className={styles.footer}>
        <Button
          text
          className={styles.linkButton}
          style={{ marginRight: "32px" }}
          onClick={() => {
            window.open("mailto:info@pantheonai.co?subject=Hello!", "_blank");
          }}
        >
          INFO@PANTHEONAI.CO
        </Button>
        <Button
          text
          className={styles.linkButton}
          style={{ fontSize: "10px" }}
          onClick={() => navigate(ROUTES.TOS)}
        >
          TERMS OF SERVICE
        </Button>
        <Button
          text
          className={styles.linkButton}
          style={{ fontSize: "10px" }}
          onClick={() => navigate(ROUTES.PRIVACY_POLICY)}
        >
          PRIVACY POLICY
        </Button>
        <Button
          text
          className={styles.linkButton}
          style={{ fontSize: "10px" }}
          onClick={() => navigate(ROUTES.SECURITY)}
        >
          SECURITY
        </Button>
      </div>
    </div>
  );
};
