import { Card } from "primereact/card";
import { TabView, TabPanel } from "primereact/tabview";
import * as client from "../../client";
import styles from "./RiskDetailsCard.module.css";

type RiskDetailsCardProps = {
  specialtyPropertyInfo: client.ReportResponse;
};

export const RiskDetailsCard: React.FC<RiskDetailsCardProps> = ({
  specialtyPropertyInfo,
}) => {
  return (
    <Card title="Risk Details">
      <div className={styles.riskDetailsContainer}>
        <TabView>
          <TabPanel
            header={`${specialtyPropertyInfo.report_json.risk_details.positive.length} Positive`}
          >
            <div className={styles.riskSignalsContainer}>
              {specialtyPropertyInfo.report_json.risk_details.positive.map(
                (riskSignal) => (
                  <RiskSignalCard
                    key={riskSignal.title}
                    riskSignal={riskSignal}
                    signalType="positive"
                  />
                )
              )}
            </div>
          </TabPanel>
          <TabPanel
            header={`${specialtyPropertyInfo.report_json.risk_details.negative.length} Negative`}
          >
            <div className={styles.riskSignalsContainer}>
              {specialtyPropertyInfo.report_json.risk_details.negative.map(
                (riskSignal) => (
                  <RiskSignalCard
                    key={riskSignal.title}
                    riskSignal={riskSignal}
                    signalType="negative"
                  />
                )
              )}
            </div>
          </TabPanel>
          <TabPanel
            header={`${specialtyPropertyInfo.report_json.risk_details.disqualifying.length} Disqualifying`}
          >
            <div className={styles.riskSignalsContainer}>
              {specialtyPropertyInfo.report_json.risk_details.disqualifying.map(
                (riskSignal) => (
                  <RiskSignalCard
                    key={riskSignal.title}
                    riskSignal={riskSignal}
                    signalType="disqualifying"
                  />
                )
              )}
            </div>
          </TabPanel>
        </TabView>
      </div>
    </Card>
  );
};

export const RiskDetailsCardPrintVariant: React.FC<RiskDetailsCardProps> = ({
  specialtyPropertyInfo,
}) => {
  return (
    <Card title="Risk Details">
      <div className={styles.riskDetailsContainer}>
        <div className={styles.riskSignalsContainer}>
          {specialtyPropertyInfo.report_json.risk_details.positive.map(
            (riskSignal) => (
              <RiskSignalCard
                key={riskSignal.title}
                riskSignal={riskSignal}
                signalType="positive"
              />
            )
          )}
          {specialtyPropertyInfo.report_json.risk_details.negative.map(
            (riskSignal) => (
              <RiskSignalCard
                key={riskSignal.title}
                riskSignal={riskSignal}
                signalType="negative"
              />
            )
          )}
          {specialtyPropertyInfo.report_json.risk_details.disqualifying.map(
            (riskSignal) => (
              <RiskSignalCard
                key={riskSignal.title}
                riskSignal={riskSignal}
                signalType="disqualifying"
              />
            )
          )}
        </div>
      </div>
    </Card>
  );
};

type RiskSignalCardProps = {
  riskSignal: client.RiskDetail;
  signalType: "positive" | "negative" | "disqualifying";
};

const RiskSignalCard: React.FC<RiskSignalCardProps> = ({
  riskSignal,
  signalType,
}) => {
  return (
    <div className={styles.riskSignalCard}>
      <div style={{ display: "flex", gap: "6px", alignItems: "center" }}>
        <RiskSignalIcon signalType={signalType} />
        <h3 style={{ margin: "0px" }}>{riskSignal.title}</h3>
      </div>
      <span>{riskSignal.description}</span>
    </div>
  );
};

type RiskSignalIconProps = {
  signalType: "positive" | "negative" | "disqualifying";
};

export const RiskSignalIcon: React.FC<RiskSignalIconProps> = ({
  signalType,
}) => {
  return (
    <>
      {signalType === "positive" && (
        <i
          className="pi pi-angle-double-up"
          style={{ color: "var(--green-600)" }}
        />
      )}
      {signalType === "negative" && (
        <i
          className="pi pi-angle-double-down"
          style={{ color: "var(--red-600)" }}
        />
      )}
      {signalType === "disqualifying" && (
        <i className="pi pi-ban" style={{ color: "var(--red-600)" }} />
      )}
    </>
  );
};
