import { PrimeReactProvider } from "primereact/api";
import { ThemeProvider } from "./themes/Theme";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Main } from "./pages/main/Main";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Route, HashRouter as Router, Routes } from "react-router-dom";

const queryClient = new QueryClient();

function App() {
  return (
    <Router>
      <PrimeReactProvider>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <Routes>
              <Route path="*" element={<Main />} />
            </Routes>
          </QueryClientProvider>
        </ThemeProvider>
      </PrimeReactProvider>
    </Router>
  );
}

export default App;
