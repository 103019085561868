import { Avatar } from "primereact/avatar";
import { USERS } from "../../pages/specialty-assistant/dashboard/users";
import { Tooltip } from "primereact/tooltip";
import styles from "./Avatar.module.css";
import classNames from "classnames";

type PantheonAvatarProps = {
  assigneeEmail: string | null;
  size?: "small" | "large";
};

export const PantheonAvatar: React.FC<PantheonAvatarProps> = ({
  assigneeEmail,
  size = "small",
}) => {
  const assignee = USERS[assigneeEmail ?? ""] ?? Object.values(USERS)[0];

  return (
    <>
      <Tooltip target={"." + styles.avatar} position="left" />
      <Avatar
        shape="circle"
        className={classNames(
          styles.avatar,
          size === "small" ? styles.avatarSmall : styles.avatarLarge
        )}
        image={assignee.photoUrl}
        data-pr-tooltip={assignee.name}
        data-pr-showdelay={500}
      />
    </>
  );
};
