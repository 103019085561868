import { useRef } from "react";
import { Button } from "primereact/button";
import { TieredMenu } from "primereact/tieredmenu";
import { ReportStatus } from "../../../api/types";
import { USERS } from "./users";
import { Avatar } from "primereact/avatar";
import { MenuItem } from "primereact/menuitem";
import { readableStatus } from "./util";
import { colorForStatus, iconForStatus } from "./StatusIcon";
import styles from "./ReportActionMenu.module.css";
import { useUpdateReport } from "../data";
import * as client from "../../../client";
import { classNames } from "primereact/utils";

type ReportActionMenuProps = {
  report: client.ReportMetadataResponse;
};

export const ReportActionMenu: React.FC<ReportActionMenuProps> = ({
  report,
}) => {
  const menu = useRef<TieredMenu>(null);
  const updateReport = useUpdateReport(report.report_id);
  const items = [
    {
      label: "Update status",
      icon: "pi pi-file-edit",
      items: Object.values(ReportStatus).map(
        (status): MenuItem => ({
          icon: (
            <span
              className={classNames(styles.circle, iconForStatus(status))}
              style={{ color: colorForStatus(status) }}
            />
          ),
          label: readableStatus(status),
          command: () => {
            updateReport.mutate({ status: { new_status: status } });
          },
        })
      ),
    },
    {
      label: "Update assignee",
      icon: "pi pi-user-edit",
      items: Object.entries(USERS).map(([email, user]) => ({
        label: user.name,
        icon: (
          <Avatar
            shape="circle"
            image={user.photoUrl}
            style={{ height: "24px", width: "24px", marginRight: "8px" }}
          />
        ),
        command: () => {
          updateReport.mutate({ assignee: { new_assignee: email } });
        },
      })),
    },
  ];

  return (
    <div>
      <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
      <Button
        text
        severity="secondary"
        icon="pi pi-ellipsis-v"
        onClick={(e) => {
          menu.current?.toggle(e);
          e.stopPropagation();
        }}
      />
    </div>
  );
};
