import { Tooltip } from "primereact/tooltip";
import { useRandomId } from "../../../util";

type CloudIconProps = {
  withTooltip?: boolean;
};

export const CATCloudIcon = ({ withTooltip }: CloudIconProps) => {
  const id = useRandomId();
  return (
    <>
      {!!withTooltip && <Tooltip target={`.${id}`} />}
      <i
        className={`${id} pi pi-cloud`}
        style={{ color: "var(--yellow-500)" }}
        data-pr-tooltip="CAT event"
        data-pr-showdelay={500}
      />
    </>
  );
};
