import wordmarkDark from "./wordmarkDark.svg";
import wordmarkLight from "./wordmarkLight.svg";
import styles from "./Wordmark.module.css";
import classNames from "classnames";
import { useContext } from "react";
import { Theme, ThemeContext } from "../../themes/Theme";
import { buildChannel } from "../../util";
import { Tag, TagProps } from "primereact/tag";

type WordmarkProps = {
  onClick?: () => void;
  className?: string;
  height?: string;
  themeOverride?: Theme;
};

const tagColor = (channel: string): TagProps["severity"] => {
  // Just giving different colors to different channels
  switch (channel) {
    case "dev-vm":
      return "warning";
    default:
      return "info";
  }
};

export const Wordmark: React.FC<WordmarkProps> = ({
  className,
  onClick,
  height = "40px",
  themeOverride,
}) => {
  const { theme } = useContext(ThemeContext);
  const isLightMode = themeOverride
    ? themeOverride === "light"
    : theme === "light";
  const channel = buildChannel();
  const showTag = channel.startsWith("dev");

  return (
    <div
      tabIndex={0}
      className={styles.wrapper}
      role="button"
      onClick={onClick}
      onKeyDown={(event) => {
        if ((event.key === "Enter" || event.key === " ") && onClick) {
          onClick();
        }
      }}
    >
      <img
        src={isLightMode ? wordmarkLight : wordmarkDark}
        className={classNames(styles.wordmark, className && className)}
        style={{ height: height }}
      />
      {showTag && (
        <Tag severity={tagColor(channel)}>{channel.toUpperCase()}</Tag>
      )}
    </div>
  );
};
