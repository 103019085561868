import { useMemo } from "react";

export const APP_VERSION = process.env.REACT_APP_VERSION;
const BUILD_CHANNEL = process.env.REACT_APP_BUILD_CHANNEL;

export const isRunningLocally = () => {
  return process.env.NODE_ENV !== "production";
};

export const buildChannel = () => {
  if (BUILD_CHANNEL === "prod") {
    return "prod";
  }
  if (BUILD_CHANNEL === "canary") {
    return "canary";
  }
  if (BUILD_CHANNEL === "dev-vm") {
    return "dev-vm";
  }
  if (BUILD_CHANNEL === "xenia") {
    return "xenia";
  }
  return "dev";
};

export const sleepMs = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isDefined = <X>(x: X | undefined): x is X => x !== undefined;

export const randomAlphaChar = () =>
  "abcdefghijklmnopqrstuvwxyz"[Math.floor(Math.random() * 26)];
export const useRandomId = () =>
  useMemo(() => Array.from({ length: 8 }, randomAlphaChar).join(""), []);
