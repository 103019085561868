import { Card } from "primereact/card";
import * as client from "../../../client";
import styles from "./CrimeCard.module.css";
import { useContext } from "react";
import { ThemeContext } from "../../../themes/Theme";
import {
  borderColorForAttributes,
  colorForAttributes,
} from "../dashboard/util";

const HARDCODED_LAKE_FOREST_II_CRIME_INFO = {
  CRIME_RISK_SCORE: 9.2,
  VIOLENT_CRIME_INDEX: 6.6,
  HOMICIDE_CRIME_INDEX: 1.4,
  RAPE_CRIME_INDEX: 7.4,
  ROBBERY_CRIME_INDEX: 12.4,
  ASSAULT_CRIME_INDEX: 5.2,
  PROPERTY_CRIME_INDEX: 12.6,
  BURGLARY_CRIME_INDEX: 13.8,
  LARCENY_CRIME_INDEX: 12.8,
  VEHICLE_THEFT_CRIME_INDEX: 11.2,
};

type CrimeCardProps = {
  specialtyPropertyInfo: client.ReportResponse;
};

export const CrimeCard: React.FC<CrimeCardProps> = () => {
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";

  return (
    <Card title="Crime">
      <div className={styles.crimeHeader}>
        <span className={styles.crimeHeaderText}>OFAC Sanctions</span>
        <div
          className={styles.roundedContainer}
          style={{
            background: colorForAttributes("good", isLightMode),
            borderColor: borderColorForAttributes("good", isLightMode),
          }}
        >
          <span className={styles.boldNumber}>{"None"}</span>
          <span className={styles.containerText}>{"OFAC SANCTIONS"}</span>
        </div>
      </div>
      <div className={styles.crimeHeader}>
        <span className={styles.crimeHeaderText}>Crime Risk Score</span>
        <CrimeInfoPiece
          title="CRIME RISK SCORE"
          percentage={HARDCODED_LAKE_FOREST_II_CRIME_INFO["CRIME_RISK_SCORE"]}
        />
      </div>
      <div className={styles.crimeHeader}>
        <span className={styles.crimeHeaderText}>Crime Details</span>
        <div className={styles.crimeGrid}>
          <CrimeInfoPiece
            title="VIOLENT CRIME"
            percentage={
              HARDCODED_LAKE_FOREST_II_CRIME_INFO["VIOLENT_CRIME_INDEX"]
            }
          />
          <CrimeInfoPiece
            title="HOMICIDE CRIME"
            percentage={
              HARDCODED_LAKE_FOREST_II_CRIME_INFO["HOMICIDE_CRIME_INDEX"]
            }
          />
          <CrimeInfoPiece
            title="RAPE CRIME"
            percentage={HARDCODED_LAKE_FOREST_II_CRIME_INFO["RAPE_CRIME_INDEX"]}
          />
          <CrimeInfoPiece
            title="ROBBERY CRIME"
            percentage={
              HARDCODED_LAKE_FOREST_II_CRIME_INFO["ROBBERY_CRIME_INDEX"]
            }
          />
          <CrimeInfoPiece
            title="ASSAULT CRIME"
            percentage={
              HARDCODED_LAKE_FOREST_II_CRIME_INFO["ASSAULT_CRIME_INDEX"]
            }
          />
          <CrimeInfoPiece
            title="PROPERTY CRIME"
            percentage={
              HARDCODED_LAKE_FOREST_II_CRIME_INFO["PROPERTY_CRIME_INDEX"]
            }
          />
          <CrimeInfoPiece
            title="BURGLARY CRIME"
            percentage={
              HARDCODED_LAKE_FOREST_II_CRIME_INFO["BURGLARY_CRIME_INDEX"]
            }
          />
          <CrimeInfoPiece
            title="LARCENY CRIME"
            percentage={
              HARDCODED_LAKE_FOREST_II_CRIME_INFO["LARCENY_CRIME_INDEX"]
            }
          />
          <CrimeInfoPiece
            title="VEHICLE THEFT CRIME"
            percentage={
              HARDCODED_LAKE_FOREST_II_CRIME_INFO["VEHICLE_THEFT_CRIME_INDEX"]
            }
          />
        </div>
      </div>
    </Card>
  );
};

type CrimeInfoPieceProps = {
  title: string;
  percentage: number;
};

const CrimeInfoPiece: React.FC<CrimeInfoPieceProps> = ({
  title,
  percentage,
}) => {
  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";
  const severity = percentage < 10 ? "good" : "medium";

  return (
    <div
      className={styles.roundedContainer}
      style={{
        background: colorForAttributes(severity, isLightMode),
        borderColor: borderColorForAttributes(severity, isLightMode),
      }}
    >
      <span className={styles.boldNumber}>{`${percentage} / 100`}</span>
      <span className={styles.containerText}>{title}</span>
    </div>
  );
};
