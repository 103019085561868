import { useContext, useMemo, useState } from "react";
import { LoggedIn } from "../../LoggedIn";
import { LoggedOut } from "../../LoggedOut";
import { APP_VERSION } from "../../util";
import styles from "./Main.module.css";
import backgroundDark from "./backgroundDark.svg";
import backgroundLight from "./backgroundLight.svg";
import { ThemeContext } from "../../themes/Theme";
import { CompanyContext, featuresForCompany } from "../../CompanyConfig";
import { useNavigate } from "react-router-dom";
import * as Token from "../../api/auth_token";

const useStoredValue = (
  key: string
): { value: string | null; set: (value: string) => void } => {
  const [count, setCount] = useState(0);

  const value = useMemo(() => localStorage.getItem(key), [count]);

  const set = (newValue: string) => {
    localStorage.setItem(key, newValue);
    setCount(count + 1);
  };

  return { value, set };
};

export const Main = () => {
  const navigate = useNavigate();
  const { value: clientId, set: setClientId } = useStoredValue("authedCompany");
  const token = Token.getToken();
  // Proper doesn't require auth tokens; otherwise, missing token means logged out.
  const isAuthed = !!clientId && (clientId === "proper" || !!token);

  const { theme } = useContext(ThemeContext);
  const isLightMode = theme === "light";
  const imgSrc = isLightMode ? backgroundLight : backgroundDark;

  const onSuccessfulAuth = (newClientId: string) => {
    setClientId(newClientId);
    const features = featuresForCompany(newClientId, navigate);
    navigate(features?.defaultRoute);
  };

  return (
    <CompanyContext.Provider value={clientId}>
      <div className={styles.app}>
        <img src={imgSrc} className={styles.backgroundImg} />
        {isAuthed ? (
          <LoggedIn clientId={clientId} />
        ) : (
          <LoggedOut onSuccessfulAuth={onSuccessfulAuth} />
        )}
        <span className={styles.versionText}>{"version " + APP_VERSION}</span>
      </div>
    </CompanyContext.Provider>
  );
};
