import styles from "./Company.module.css";
import johnProfile from "./JohnProfile.jpg";
import gregProfile from "./GregProfile.jpg";
import { CompanyMemberCard } from "./CompanyMemberCard";

export const Company = () => {
  return (
    <div className={styles.container}>
      <span className={styles.headerText}>MEET THE TEAM</span>
      <div className={styles.cardContainer}>
        <CompanyMemberCard
          name="GREG KARABINOS"
          title="FOUNDER"
          infoLines={[
            "Middlebury class of ‘15 graduate",
            "Data scientist with 7 years of insurance industry experience",
          ]}
          imageSrc={gregProfile}
        />
        <CompanyMemberCard
          name="JOHN KARABINOS"
          title="FOUNDER"
          infoLines={[
            "Middlebury class of ‘16 graduate",
            "Ex-Dropbox software engineer",
            "Startup acquired by Dropbox in 2019",
          ]}
          imageSrc={johnProfile}
        />
      </div>
    </div>
  );
};
