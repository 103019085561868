import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import * as client from "../../client";
import { ExactMoney, formatAbbreviatedMoney } from "./Money";
import { useMemo, useState } from "react";
import { Button } from "primereact/button";
import styles from "./AddressCard.module.css";
import { MultiSelect } from "primereact/multiselect";

type AddressCardProps = {
  sovs: client.Sov[];
  isCasualty: boolean;
  setMapVisible?: (visible: boolean) => void;
};

// XXX very dangerous
const TerribleMoney = ({ dollars }: { dollars?: number }) => {
  if (dollars === undefined) {
    return <></>;
  }
  const cents = Math.round(dollars * 100);
  return <ExactMoney cents={cents} />;
};

export const AddressCard: React.FC<AddressCardProps> = ({
  sovs,
  isCasualty,
  setMapVisible,
}) => {
  const filenames = sovs.map((sov) => {
    return { name: sov.filename };
  });

  const [selectedFilenames, setSelectedFilenames] = useState(
    sovs
      .filter((sov) => sov.is_enabled)
      .map((sov) => {
        return { name: sov.filename };
      })
  );

  // Show enabled addresses
  const addresses = useMemo(
    () =>
      sovs
        .filter((sov) =>
          selectedFilenames.some((filename) => filename.name === sov.filename)
        )
        .flatMap((sov) => sov.addresses),
    [selectedFilenames]
  );

  const hasCountry = useMemo(
    () => addresses.every((a) => !!a.country),
    [addresses]
  );
  const totalTIV = useMemo(
    () => addresses.reduce((total, address) => total + address.tiv, 0),
    [addresses]
  );
  const totalBuildingValue = useMemo(
    () =>
      addresses.reduce(
        (total, address) =>
          total +
          (address.building_value && !isNaN(Number(address.building_value))
            ? Number(address.building_value)
            : 0),
        0
      ),
    [addresses]
  );
  return (
    <Card
      title="Statement of Values"
      subTitle={
        <div className={styles.subtitleContainer}>
          <span>
            {isCasualty
              ? `Total Building Value: ${formatAbbreviatedMoney(
                  totalBuildingValue
                )}`
              : `Total Insured Value: ${formatAbbreviatedMoney(totalTIV)}`}
          </span>
          {setMapVisible ? (
            <Button
              text
              icon="pi pi-map"
              label="Show map"
              onClick={() => setMapVisible(true)}
            />
          ) : null}
          <MultiSelect
            value={selectedFilenames}
            onChange={(e) => setSelectedFilenames(e.value)}
            options={filenames}
            optionLabel="name"
            placeholder="Select Files/Sheets"
            maxSelectedLabels={3}
            style={{ width: "260px" }}
            selectAllLabel="All"
          />
        </div>
      }
    >
      <DataTable
        value={addresses}
        paginator
        rows={100}
        className={styles.table}
      >
        <Column
          field="street_address"
          header="Address"
          style={{ maxWidth: "200px" }}
        />
        <Column field="city" header="City" />
        <Column sortable field="state" header="State" />
        <Column field="zip" header="ZIP" />
        {hasCountry && <Column field="country" header="Country" />}
        <Column
          field="occupancy_code"
          header="Occupancy"
          body={(data: client.Address) =>
            `${data.occupancy_code_type} ${data.occupancy_code}`
          }
        />
        <Column
          field="construction_code"
          header="Construction"
          body={(data: client.Address) =>
            `${data.construction_code_type} ${data.construction_code}`
          }
        />
        <Column field="year_built" header="Year built" />
        <Column field="number_of_stories" header="Num Stories" />
        <Column
          field="building_value"
          header="Building Value"
          body={(data: client.Address) =>
            data.building_value && (
              <TerribleMoney dollars={parseFloat(data.building_value)} />
            )
          }
        />
        <Column
          field="bpp_value"
          header="BPP Value"
          body={(data: client.Address) =>
            data.bpp_value && (
              <TerribleMoney dollars={parseFloat(data.bpp_value)} />
            )
          }
        />
        <Column
          field="business_interruption_value"
          header="BI Value"
          body={(data: client.Address) =>
            data.business_interruption_value && (
              <TerribleMoney
                dollars={parseFloat(data.business_interruption_value)}
              />
            )
          }
        />
        {!isCasualty && (
          <Column
            sortable
            field="tiv"
            header="TIV"
            body={(data: client.Address) => (
              <TerribleMoney dollars={data.tiv} />
            )}
          />
        )}
      </DataTable>
    </Card>
  );
};
