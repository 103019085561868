import styles from "./PrintableReport.module.css";
import * as client from "../../client";
import { LayersCard } from "./LayersCard";
import { Wordmark } from "../../components/wordmark/Wordmark";
import { RiskDetailsCardPrintVariant } from "./RiskDetailsCard";
import { CompanyInfoHeader } from "./CompanyInfoHeader";
import { LossRunCard } from "./loss-run/LossRunCard";
import "./PrintableReportStyles.css";

type PrintableReportProps = {
  specialtyPropertyInfo: client.ReportResponse;
  isCasualty: boolean;
};

export const PrintableReport: React.FC<PrintableReportProps> = ({
  specialtyPropertyInfo,
  isCasualty,
}) => {
  return (
    <div className={styles.printContainer}>
      <div className={styles.headerContainer}>
        <h2 className={styles.header}>
          {specialtyPropertyInfo.report_json.company_info.company_name}
        </h2>
        <Wordmark height="24px" themeOverride="light" />
      </div>
      <CompanyInfoHeader specialtyPropertyInfo={specialtyPropertyInfo} />
      <div className={styles.pageBreakAvoid}>
        <RiskDetailsCardPrintVariant
          specialtyPropertyInfo={specialtyPropertyInfo}
        />
      </div>
      <div className={styles.pageBreakAvoid}>
        <LayersCard
          isCasualty={isCasualty}
          layers={specialtyPropertyInfo.report_json.layer_info.map((layer) => ({
            startingPoint: layer.starting_point,
            range: layer.range,
            target: layer.target,
            rated: layer.rated,
          }))}
        />
      </div>
      <div className={styles.pageBreakAvoid}>
        <LossRunCard
          lossRunInfo={specialtyPropertyInfo.report_json.loss_run}
          deductibleInfo={specialtyPropertyInfo.report_json.deductible_info}
        />
      </div>
    </div>
  );
};
