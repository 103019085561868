import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  UpdateReport,
  listReport,
  listReports,
  updateReport,
} from "../../api/routes";

export const useReports = () => {
  return useQuery({
    queryKey: ["reports"],
    queryFn: async () => {
      return await listReports();
    },
  });
};

export const useReport = (reportId: string) => {
  return useQuery({
    queryKey: ['reports', reportId],
    queryFn: async () => {
      return await listReport(reportId);
    },
  });
};

export const useUpdateReport = (reportId: string) => {
  const key = ["reports", reportId];
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: key,
    mutationFn: async (update: UpdateReport) =>
      await updateReport(reportId, update),
    onSuccess: () => {
      // invalidate prefix matches against queryKeys
      queryClient.invalidateQueries({ queryKey: ["reports"] });
    },
  });
};
