import * as React from "react";
import { MenuItem } from "primereact/menuitem";
import { ROUTES } from "./routes";
import { useMemo } from "react";

export type CompanyConfig = {
  isStrEnabled: boolean;
  isPropertyAssistantEnabled: boolean;
  isCasualtyAssistantEnabled: boolean;
  isRenewalEnabled: boolean;
  menuItems: MenuItem[];
  defaultRoute: string;
};

type Navigate = (route: string) => void;
type MakeMenuItem = (navigate: Navigate) => MenuItem;

const STR_MENU_ITEM: MakeMenuItem = (navigate) => ({
  id: "str",
  label: "STR Underwriter Assistant",
  icon: "pi pi-home",
  command: () => {
    navigate(ROUTES.STR);
  },
});

const PROPERTY_MENU_ITEM: MakeMenuItem = (navigate) => ({
  id: "property",
  label: "Property Dashboard",
  icon: "pi pi-building",
  command: () => {
    navigate(ROUTES.DASHBOARD_PROPERTY);
  },
});

const CASUALTY_MENU_ITEM: MakeMenuItem = (navigate) => ({
  id: "casualty",
  label: "Casualty Dashboard",
  icon: "pi pi-receipt",
  command: () => {
    navigate(ROUTES.DASHBOARD_CASUALTY);
  },
});

const RENEWAL_MENU_ITEM: MakeMenuItem = (navigate) => ({
  id: "renewal",
  label: "Renewal Assistant",
  icon: "pi pi-address-book",
  command: () => {
    navigate(ROUTES.RENEWAL);
  },
});

export const featuresForCompany = (
  clientId: string,
  navigate: Navigate
): CompanyConfig => {
  console.log(clientId);
  switch (clientId) {
    case "proper":
      return {
        isStrEnabled: true,
        isPropertyAssistantEnabled: false,
        isCasualtyAssistantEnabled: false,
        isRenewalEnabled: false,
        menuItems: [],
        defaultRoute: ROUTES.STR,
      };
    case "canopius":
      return {
        isStrEnabled: false,
        isPropertyAssistantEnabled: true,
        isCasualtyAssistantEnabled: true,
        isRenewalEnabled: false,
        menuItems: [PROPERTY_MENU_ITEM(navigate), CASUALTY_MENU_ITEM(navigate)],
        defaultRoute: ROUTES.DASHBOARD_PROPERTY,
      };
    case "pantheon":
      return {
        isStrEnabled: true,
        isPropertyAssistantEnabled: true,
        isCasualtyAssistantEnabled: true,
        isRenewalEnabled: true,
        menuItems: [
          STR_MENU_ITEM(navigate),
          PROPERTY_MENU_ITEM(navigate),
          CASUALTY_MENU_ITEM(navigate),
          RENEWAL_MENU_ITEM(navigate),
        ],
        defaultRoute: ROUTES.DASHBOARD_PROPERTY,
      };
    case "intact":
      return {
        isStrEnabled: false,
        isPropertyAssistantEnabled: true,
        isCasualtyAssistantEnabled: true,
        isRenewalEnabled: false,
        menuItems: [],
        defaultRoute: ROUTES.DASHBOARD_PROPERTY,
      };
    case "norfolk_dedham":
      return {
        isStrEnabled: false,
        isPropertyAssistantEnabled: false,
        isCasualtyAssistantEnabled: false,
        isRenewalEnabled: true,
        menuItems: [],
        defaultRoute: ROUTES.RENEWAL,
      };
  }

  throw new Error(`unknown company ${clientId}`);
};

export const useFeaturesForComapny = (
  clientId: string,
  navigate: Navigate
): CompanyConfig => {
  return useMemo(
    () => featuresForCompany(clientId, navigate),
    [clientId, navigate]
  );
};

export const CompanyContext = React.createContext<string | null>(null);
