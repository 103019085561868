const AUTH_TOKEN_KEY = "authToken";

let GLOBAL_TOKEN: string | null = localStorage.getItem(AUTH_TOKEN_KEY);

export const setToken = (token: string | null) => {
  GLOBAL_TOKEN = token;
  if (token === null) {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  } else {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
  }
};

export const getToken = (): string | null => {
  return GLOBAL_TOKEN;
};
