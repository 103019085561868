import { useRef, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import styles from "./UwAssistant.module.css";
import {
  generatePdfFromListingObject,
  generatePdfFromPropertyUrls,
} from "../../api/routes";
import { Toast } from "primereact/toast";
import { ListingObject } from "../../api/types";
import { SelectButton } from "primereact/selectbutton";
import { ListingIdForm } from "./ListingIdForm";
import { PropertyLinksForm } from "./PropertyLinksForm";
import { Checkbox } from "primereact/checkbox";
import { Tooltip } from "primereact/tooltip";
import { useRandomId } from "../../util";

type ListingPdf = {
  listingId: string;
  pdfUrl: string;
  fromPropertyLinks: boolean;
};

const selectButtonTypes = ["By rental listing id", "By property links"];
type SelectButtonType = (typeof selectButtonTypes)[number];

export const UwAssistant = () => {
  const [listingIdTextValues, setListingIdTextValues] = useState<ListingObject>(
    {
      airbnb: "",
      vrbo: "",
      evolve: "",
      furnished_finder: "",
    }
  );
  const [urlTextValue1, setUrlTextValue1] = useState("");
  const [urlTextValue2, setUrlTextValue2] = useState("");
  const [urlTextValue3, setUrlTextValue3] = useState("");

  const [loading, setLoading] = useState(false);
  const [listingPdfs, setListingPdfs] = useState<ListingPdf[]>([]);
  const [selectButtonValue, setSelectButtonValue] = useState<SelectButtonType>(
    "By rental listing id"
  );
  const [isRenewal, setIsRenewal] = useState(false);

  const toast = useRef<Toast>(null);

  const buttonDisabled =
    selectButtonValue === "By rental listing id"
      ? listingIdTextValues.airbnb === "" &&
        listingIdTextValues.vrbo === "" &&
        listingIdTextValues.evolve === "" &&
        listingIdTextValues.furnished_finder === ""
      : urlTextValue1 === "" && urlTextValue2 === "" && urlTextValue3 === "";

  const generatePdf = async () => {
    if (loading) {
      return;
    }
    setListingIdTextValues({
      airbnb: "",
      vrbo: "",
      evolve: "",
      furnished_finder: "",
    });
    setUrlTextValue1("");
    setUrlTextValue2("");
    setUrlTextValue3("");

    setLoading(true);
    const responseData =
      selectButtonValue === "By rental listing id"
        ? await generatePdfFromListingObject(listingIdTextValues, isRenewal)
        : await generatePdfFromPropertyUrls(
            [urlTextValue1, urlTextValue2, urlTextValue3].filter(
              (url) => url.length > 0
            ),
            isRenewal
          );
    setLoading(false);
    if (responseData === undefined) {
      toast.current?.show({
        severity: "warn",
        summary: "PDF Generation Failed",
        detail:
          "Failed to generate the PDF with an unknown error. Please try again. If the issue persists, email greg@pantheonai.co",
        sticky: true,
      });
      return;
    } else if (
      responseData.imageFailure &&
      responseData.imageFailuresForListingToday >= 2
    ) {
      toast.current?.show({
        severity: "warn",
        summary: "Image Failure",
        detail: `Failed to gather images for ${responseData.listingTypeDisplayName}. This is image failure number ${responseData.imageFailuresForListingToday} for ${responseData.listingTypeDisplayName} in the last 24 hours. If this is a common failure, please email greg@pantheonai.co`,
        sticky: true,
      });
    } else if (
      responseData.totalFailure &&
      responseData.totalFailuresForListingToday >= 2
    ) {
      toast.current?.show({
        severity: "warn",
        summary: "Scraping Failure",
        detail: `Failed to scrape content for ${responseData.listingTypeDisplayName}. This is scraping failure number ${responseData.totalFailuresForListingToday} for ${responseData.listingTypeDisplayName} in the last 24 hours. If this is a common failure, please email greg@pantheonai.co`,
        sticky: true,
      });
    }

    if (!responseData.arrayBuffer) {
      return;
    }

    const blob = new Blob([responseData.arrayBuffer], {
      type: "application/pdf",
    });

    // Display the PDF inline
    const url = window.URL.createObjectURL(blob);
    setListingPdfs((currentBlobUrls) => {
      const fromPropertyLinks = selectButtonValue === "By property links";
      const date = new Date();
      const readableDate = date.toLocaleString("en-US");

      return [
        {
          pdfUrl: url,
          listingId: readableDate,
          fromPropertyLinks: fromPropertyLinks,
        },
        ...currentBlobUrls,
      ];
    });
  };

  const tooltipId = useRandomId();

  return (
    <div className={styles.topContainer}>
      <Toast ref={toast} />
      <div className={styles.listingsContainer}>
        <p style={{ color: "var(--text-color-secondary)", marginTop: "32px" }}>
          {listingPdfs.length === 0
            ? "No listings yet"
            : "These will disappear on page refresh"}
        </p>

        {listingPdfs.map((airbnbPdf) => (
          <Card key={airbnbPdf.listingId} style={{ width: "100%" }}>
            <a href={airbnbPdf.pdfUrl} target="_blank" rel="noreferrer">
              {airbnbPdf.fromPropertyLinks
                ? "Property link listing"
                : `Listing ${airbnbPdf.listingId}`}
            </a>
          </Card>
        ))}
      </div>
      <div className={styles.cardContainer}>
        <Card className={styles.card}>
          <div className={styles.selectButtonContainer}>
            <SelectButton
              value={selectButtonValue}
              onChange={(e) => setSelectButtonValue(e.value)}
              options={selectButtonTypes}
            />
          </div>

          {selectButtonValue === "By rental listing id" && (
            <ListingIdForm
              textValues={listingIdTextValues}
              setTextValues={setListingIdTextValues}
            />
          )}
          {selectButtonValue === "By property links" && (
            <PropertyLinksForm
              urlTextValue1={urlTextValue1}
              urlTextValue2={urlTextValue2}
              urlTextValue3={urlTextValue3}
              setUrlTextValue1={setUrlTextValue1}
              setUrlTextValue2={setUrlTextValue2}
              setUrlTextValue3={setUrlTextValue3}
            />
          )}

          <div className={styles.renewalContainer}>
            <Checkbox
              onChange={(e) => setIsRenewal(e.checked ?? false)}
              checked={isRenewal}
            />
            <span>Renewal</span>
            <span className={tooltipId} data-pr-showdelay={500}>
              <i
                className="pi pi-info-circle"
                style={{
                  color: "var(--text-color)",
                }}
              />
              <Tooltip target={"." + tooltipId} style={{ maxWidth: "300px" }}>
                Checking this box will ask a shorter list of questions,
                specifically tailored for renewals.
              </Tooltip>
            </span>
          </div>
          <Button
            className={styles.submitButton}
            label="Generate PDF"
            disabled={buttonDisabled}
            onClick={generatePdf}
            loading={loading}
          />
          {loading && <p>Grab a coffee, this might take a few minutes...</p>}
        </Card>
      </div>
    </div>
  );
};
