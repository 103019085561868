import styles from "./Landing.module.css";
import { motion } from "framer-motion";
import landingImage from "./landingText.svg";

type LandingProps = {
  withInitialAnimation: boolean;
};

export const Landing: React.FC<LandingProps> = ({ withInitialAnimation }) => {
  return (
    <div className={styles.container}>
      {withInitialAnimation ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 4, ease: "easeInOut", delay: 0.5 }}
        >
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1.0 }}
            transition={{ duration: 6, ease: "easeInOut", delay: 0.5 }}
          >
            <img className={styles.headerTextImage} src={landingImage} />
          </motion.div>
        </motion.div>
      ) : (
        <img className={styles.headerTextImage} src={landingImage} />
      )}
    </div>
  );
};
