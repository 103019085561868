import styles from "./SpecialtyReport.module.css";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { TabView, TabPanel } from "primereact/tabview";
import { Conversation } from "./Conversation";
import { LayersCard } from "./LayersCard";
import { LossRunCard } from "./loss-run/LossRunCard";
import { AddressCard } from "./AddressCard";
import { useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useParams } from "react-router-dom";
import { useReport } from "./data";
import { ReportActionMenu } from "./dashboard/ReportActionMenu";
import { StatusIcon } from "./dashboard/StatusIcon";
import { PantheonAvatar } from "../../components/avatar/Avatar";
import { PrintableReport } from "./PrintableReport";
import { RiskDetailsCard } from "./RiskDetailsCard";
import { CompanyInfoHeader } from "./CompanyInfoHeader";
import { AppetiteModal } from "./appetite/AppetiteModal";
import { AppetiteIcon } from "./appetite/AppetiteIcon";
import { USERS } from "./dashboard/users";
import { appetiteScoreForReportInfo } from "./dashboard/util";
import { FinancialCard } from "./financial/FinancialCard";
import { CrimeCard } from "./crime/CrimeCard";

export const SpecialtyReport: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  // This should never happen
  if (id === undefined) {
    return <></>;
  }

  const reportResponse = useReport(id);
  const specialtyPropertyInfo = useMemo(
    () => (reportResponse.isSuccess ? reportResponse.data : undefined),
    [reportResponse]
  );

  // TODO: use a flag on the report
  const isCasualty =
    specialtyPropertyInfo?.report_json.company_info.company_name ===
    "Lake Forest II";

  const [isFireMapVisible, setIsFireMapVisible] = useState(false);
  const [appetiteModalVisible, setAppetiteModalVisible] = useState(false);

  // TODO: return some sort of failure state if we couldn't find the report
  if (specialtyPropertyInfo === undefined) {
    return <></>;
  }

  const assignee =
    USERS[specialtyPropertyInfo.assignee ?? ""] ?? Object.values(USERS)[0];

  return (
    <>
      <PrintableReport
        isCasualty={isCasualty}
        specialtyPropertyInfo={specialtyPropertyInfo}
      />
      <AppetiteModal
        report={specialtyPropertyInfo.report_json}
        visible={appetiteModalVisible}
        onHide={() => setAppetiteModalVisible(false)}
      />
      <div className={styles.container}>
        <div className={styles.verticalContainer}>
          <Card
            title={specialtyPropertyInfo.report_json.company_info.company_name}
            subTitle={
              <div className={styles.statusContainer}>
                <div className={styles.roundedHeaderPiece}>
                  <div className={styles.assigneeContainer}>
                    <PantheonAvatar
                      assigneeEmail={specialtyPropertyInfo.assignee}
                    />
                    <span className={styles.assigneeName}>{assignee.name}</span>
                  </div>
                </div>
                <div className={styles.roundedHeaderPiece}>
                  <StatusIcon status={specialtyPropertyInfo.status} />
                </div>
                <div className={styles.roundedHeaderPiece}>
                  <AppetiteIcon
                    onClick={() => setAppetiteModalVisible(true)}
                    size="full_width"
                    appetite={appetiteScoreForReportInfo(
                      specialtyPropertyInfo.report_json.business_classification,
                      specialtyPropertyInfo.report_json.appetite_score
                    )}
                  />
                </div>
              </div>
            }
            style={{ position: "relative" }}
          >
            <CompanyInfoHeader specialtyPropertyInfo={specialtyPropertyInfo} />
            <span>
              {
                specialtyPropertyInfo.report_json.company_info
                  .company_description
              }
            </span>
            <div className={styles.topRightButtons}>
              <Button
                className={styles.textButton}
                onClick={() => window.print()}
                severity="secondary"
                text
                icon="pi pi-download"
              />
              <ReportActionMenu
                report={{
                  report_metadata: { ...specialtyPropertyInfo.report_json },
                  ...specialtyPropertyInfo,
                }}
              />
            </div>
          </Card>
          <TabView className={styles.tabView}>
            <TabPanel header="Risk Details">
              <RiskDetailsCard specialtyPropertyInfo={specialtyPropertyInfo} />
            </TabPanel>
            {isCasualty && (
              <TabPanel header="Finances">
                <FinancialCard specialtyPropertyInfo={specialtyPropertyInfo} />
              </TabPanel>
            )}
            {isCasualty && (
              <TabPanel header="Crime">
                <CrimeCard specialtyPropertyInfo={specialtyPropertyInfo} />
              </TabPanel>
            )}
            <TabPanel header="Layers">
              <LayersCard
                isCasualty={isCasualty}
                layers={specialtyPropertyInfo.report_json.layer_info.map(
                  (layer) => ({
                    startingPoint: layer.starting_point,
                    range: layer.range,
                    target: layer.target,
                    rated: layer.rated,
                  })
                )}
              />
            </TabPanel>
            <TabPanel header="Loss Run">
              <LossRunCard
                lossRunInfo={specialtyPropertyInfo.report_json.loss_run}
                deductibleInfo={
                  specialtyPropertyInfo.report_json.deductible_info
                }
              />
            </TabPanel>
            <TabPanel header="SOV">
              <AddressCard
                isCasualty={isCasualty}
                sovs={specialtyPropertyInfo.report_json.sovs}
                setMapVisible={
                  specialtyPropertyInfo.report_json.html_map
                    ? setIsFireMapVisible
                    : undefined
                }
              />
            </TabPanel>
          </TabView>
          <Dialog
            header={`${specialtyPropertyInfo.report_json.company_info.company_name} Addresses`}
            visible={isFireMapVisible}
            style={{
              width: "calc(100vw - 72px)",
              height: "calc(100vh - 72px)",
            }}
            onHide={() => {
              if (!isFireMapVisible) return;
              setIsFireMapVisible(false);
            }}
          >
            <iframe
              srcDoc={specialtyPropertyInfo.report_json.html_map ?? ""}
              title="Fire Clusters"
              className={styles.iframe}
            />
          </Dialog>
        </div>
        <Conversation specialtyPropertyInfo={specialtyPropertyInfo} />
      </div>
    </>
  );
};
